import React, { useState, useEffect } from 'react';
import { Parallax } from 'react-scroll-parallax';

const About = () => {
    const [services, setServices] = useState([])

    useEffect(() => {
        async function getServices() {
            await fetch('https://allbackend.dk/wp-json/wp/v2/service')
                .then(response => response.json())
                .then(data => {
                    setServices(data)
                })
        }
        getServices()
    }, [])

    return (
      <div className="about-section" id="about">
        <div className="about-left">
          <div className="header-text-blinking">
            <h1>A</h1>
            <h1 className="about-blinkingText-yellow-character">B</h1>
            <h1>
              OUT.<span className="blinking-cursor">|</span>
            </h1>
          </div>
          <div className="header-text-blinking-mobile">
            <h1>
              A<span className="about-blinkingText-yellow-character">B</span>
              OUT.<span className="blinking-cursor">|</span>
            </h1>
          </div>
        </div>
        <div className="about-right-desktop">
          <Parallax
            className="custom-class"
            x={[0, -20]}
            y={[250, 0]}
            tagOuter="figure"
          >
            <div className="about-right">
              <h2>I am Zoltan Hricz</h2>
              <h2>developer / designer</h2>
              <div className="about-yellow-header">WHAT I DO</div>
              <div className="services-list">
                {services.reverse().map(service => {
                  return <p key={service.id}>{service.title.rendered}</p>;
                })}
              </div>
            </div>
          </Parallax>
        </div>
        <div className="about-right-mobile">
          <div className="about-right">
            <h2>I am Zoltan Hricz</h2>
            <h2>web designer / developer</h2>
            <div className="about-yellow-header">WHAT I DO</div>
            <div className="services-list">
              {services.reverse().map(service => {
                return <p key={service.id}>{service.title.rendered}</p>;
              })}
            </div>
          </div>
        </div>
      </div>
    );
}

export default About