import React from 'react';
import App from './App';
import { ParallaxProvider } from 'react-scroll-parallax';

const AppContainer = () => {
    return (
        <ParallaxProvider>
            <App />
        </ParallaxProvider>
    )
}

export default AppContainer