import React, { useState, useEffect } from 'react';
import Typed from 'react-typed';
import { Parallax } from 'react-scroll-parallax';

const Header = () => {
    const [some, setSome] = useState([]);

    useEffect(() => {
        async function fetchSOME() {
            await fetch('https://allbackend.dk/wp-json/wp/v2/some_link')
                .then(response => response.json())
                .then(data => {
                    setSome(data)
                })
        }
        fetchSOME()
    }, [])

    return (
        <div className="header">
            <div className="heroText-desktop">
                <Parallax className="custom-class" x={[40, -60]} y={[-120, 150]} tagOuter="figure">
                    <Typed
                        className="type"
                        strings={['HEL']}
                        typeSpeed={200}
                        showCursor={false}
                    />
                </Parallax>
                <Parallax className="custom-class" x={[-30, 10]} y={[120, -150]} tagOuter="figure">
                    <Typed
                        className="type-black"
                        strings={['LO.']}
                        startDelay={900}
                        typeSpeed={200}
                        
                    />
                </Parallax>
            </div>
            <div className="heroText-mobile">
                
                    <Typed
                        className="type"
                        strings={['HEL']}
                        typeSpeed={200}
                        showCursor={false}
                    />
                
                    <Typed
                        className="type-black"
                        strings={['LO.']}
                        startDelay={900}
                        typeSpeed={200}
                        
                    />
            </div>
            <div className="social-icons">
                {some.reverse().map((so) => {
                    return <a key={so.id} href={so.acf.link}><img src={so.acf.image} alt="social-link" className="social-images"/></a>
                })}
            </div>
        </div>
    )
}

export default Header