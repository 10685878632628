import React from 'react'
import Tilt from 'react-parallax-tilt';

const Client = ({ clients, loading }) => {
    if(loading) {
        return <h2>Loading...</h2>
    }

    return (
        <div className="client-details-container">
            {clients.reverse().map((client) => (
                <div key={client.id} className="client-container">
                    <div className="client-description-container">
                        <a href={client.acf.link} target="_blank" rel="noopener noreferrer">
                            <p className="intro">{client.acf.intro} <br /> <span>{client.acf.client}</span></p>
                            <p className="description">{client.acf.description}</p>
                        </a>
                    </div>
                    <div className="client-display-container">
                    <Tilt>
                        <div>
                            <a href={client.acf.link} target="_blank" rel="noopener noreferrer"><img src={client.acf.image} alt="client-website" style={{borderRadius: client.acf.image.slice(client.acf.image.length -11) === "phone-1.svg" ?  "25px" : "0px"}}/></a>
                        </div>
                    </Tilt>
                        <p className="technology-description">Tech: {client.acf.technology}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Client