import React, { useState, useEffect } from 'react'
import Repository from './Repository'
import Pagination from './Pagination'

const Projects = () => {
    const [project, setProject] = useState({})
    const { github_link, intro, technologies } = project
    const [repositories, setRepositories] = useState([])
    //const [languages, setLanguages] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage] = useState(4)

    useEffect(() => {
        async function fetchProject() {
            await fetch('https://allbackend.dk/wp-json/wp/v2/project')
                .then(response => response.json())
                .then(data => {
                    setProject(data[0].acf)
                })
        }
        fetchProject()
    }, [])

    useEffect(() => {
        async function fetchRepositories() {
            await fetch('https://api.github.com/users/hriczzoli/repos')
                .then(response => response.json())
                .then(data => {
                    setRepositories(data)
                })
        }
        fetchRepositories()
    }, [])

    //Get current repository
    const indexOfLastRepo = currentPage * itemsPerPage
    const indexOfFirstRepo = indexOfLastRepo - itemsPerPage
    const currentRepos = repositories.slice(indexOfFirstRepo, indexOfLastRepo)

    //Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    return (
        <div className="projects-container" id="projects">
            <div className="header-text-blinking">
                <h1>PRO</h1>
                <h1 className="lower-text">JECTS.<span className="blinking-cursor">|</span></h1>
            </div>
            <div className="header-text-blinking-mobile">
                <h1>PRO</h1>
                <h1 className="lower-text">JECTS.<span className="blinking-cursor">|</span></h1>
            </div>
            <div className="project-content-container">
                <div className="main-content">
                    <p className="intro">My repositories on <a href={github_link} target="_blank" rel="noopener noreferrer">GitHub</a></p>
                    <p className="">{intro}</p>
                    <p className="description">{technologies}</p>
                </div>
                <div className="repos-container">
                    {currentRepos.map((repository) => {
                        return <Repository key={repository.id} repository={repository}/>
                    })}
                </div>
            </div>
            <Pagination itemsPerPage={itemsPerPage} totalItems={repositories.length} paginate={paginate} currentPage={currentPage}/>
        </div>
    )
}

export default Projects