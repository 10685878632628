import React from 'react';
import Navbar from './Navbar';
import Header from './Header';
import About from './About';
import Portfolio from './Portfolio';
import Projects from './Projects';

const App = () => {
  return (
    <div>
      <Navbar />
      <Header />
      <About />
      <div className="divider-mobile"></div>
      <Portfolio />
      <div className="divider-mobile2"></div>
      <Projects />
    </div>
  );
}

export default App;
