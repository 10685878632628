import React, { useState, useEffect } from 'react';
import Client from './Client'
import Pagination from './Pagination'

const Portfolio = () => {
    const [clients, setClients] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage] = useState(1)

    useEffect(() => {
        async function fetchClients() {
            await fetch('https://allbackend.dk/wp-json/wp/v2/client')
            .then(response => response.json())
            .then(data => {
                setClients(data)
                setLoading(false)
            })
        }
        fetchClients()
    }, [])


    //Get current client
    const indexOfLastClient = currentPage * itemsPerPage
    const indexOfFirstClient = indexOfLastClient - itemsPerPage
    const currentClients = clients.slice(indexOfFirstClient, indexOfLastClient)

    //Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    return (
        <div className="portfolio-container" id="portfolio">
                <div className="header-text-blinking">
                    <h1>PORT</h1>
                    <h1 className="lower-text">FOLIO.<span className="blinking-cursor">|</span></h1>
                </div>
                <div className="header-text-blinking-mobile">
                <h1>PORT</h1>
                <h1 className="lower-text">FOLIO.<span className="blinking-cursor">|</span></h1>
                </div>
                <Client clients={currentClients} loading={loading}/>
                <Pagination itemsPerPage={itemsPerPage} totalItems={clients.length} paginate={paginate} currentPage={currentPage}/>
        </div>
    )
}

export default Portfolio