import React, { useState, useEffect } from 'react'
import { stack as Menu } from 'react-burger-menu'

const Navbar = () => {
    const [logo, setLogo] = useState("")
    const [scroll, setScroll] = useState(0)

    useEffect(() => {
        async function getLogo() {
            await fetch('https://allbackend.dk/wp-json/wp/v2/pages')
                .then(response => response.json())
                .then(data => {
                    setLogo(data[0].acf.logo)
                })
        }
        getLogo()
    }, [])

    useEffect(() => {
        document.addEventListener("scroll", () => {
            const scrollPosition = window.scrollY
            if (scrollPosition > 30) {
                setScroll(scrollPosition)
            } else {
                setScroll(0)
            }
        })
    })

    return(
        <div className={
            scroll === 0 ? "navbar" : "nav-scroll"
        }>
            <img src={logo} alt="logo" className="logo"/>
            <Menu right={true}>
                <a  className="menu-item" href="/">Home</a>
                <a  className="menu-item" href="#about">About</a>
                <a  className="menu-item" href="#portfolio">Portfolio</a>
                <a  className="menu-item" href="#projects">Projects</a>
            </Menu>
        </div>
    )
}

export default Navbar