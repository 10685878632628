import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons'

const Repository = ({ repository }) => {
    //const [languages, setLanguages] = useState([])

    // useEffect(() => {
    //     async function fetchLanguages() {
    //         await fetch(`https://api.github.com/repos/hriczzoli/${repository.name}/languages`)
    //             .then(response => response.json())
    //             .then(data => {
    //                 //console.log(data)
    //                 setLanguages(data)
    //             })
    //     }
    //     fetchLanguages()
    // }, [])
    //console.log(languages)

    return (
        <div className="repository-card">
            <a href={repository.html_url} target="_blank" rel="noopener noreferrer">
                <div className="card-header">
                    <h3>{repository.name}</h3>
                    <FontAwesomeIcon icon={faCodeBranch} size="2x"/>
                </div>
                <span>{repository.language}</span>
                <p>{repository.description}</p>
            </a>
        </div>
    )
}

export default Repository